import "../un_terzo/un_terzo.css";
import { Link } from "react-router-dom";
import "aos/dist/aos.css";

const UnTerzo = (props) => {
  return (
    <div className="element">
      <div className="element_inside_container">
        <div className="container_inner">
          <div className="spot_title">
            <Link to={props.spot.path}>
              <h2 className="pt_spots">{props.spot.titolo}</h2>
            </Link>
          </div>
        </div>
        <div className="bg_spot">
          <img src={props.spot.cover} alt={props.spot.titolo} />
        </div>
      </div>
    </div>
  );
};
export default UnTerzo;
