import React from "react";
import "./subfilm.css";
import Footer from "../footer/footer";
import SubNav from "../subnav/subnav";
import yt from "../../../images/Loghi/yt.png";

const SubFilm = (props) => {
  return (
    <div className="">
      <header>
        <SubNav></SubNav>
      </header>
      <main>
        <div className="allfat_cont">
          <div className="cover_cont">
            <img src={props.data.cover} alt="" />
          </div>
          <div className="details_outer">
            <div className="first_half">
              <div className="half_container">
                <div className="film_title">
                  <h1>{props.data.title}</h1>
                </div>
                <div className="credits_">
                  <p>{props.data.prod}</p>
                  <p>Cast: {props.data.attori}</p>
                  <p>Soggetto: {props.data.soggetto}</p>
                  <p>Sceneggiatura: {props.data.sceneggiatura}</p>
                  <p>Fotografia: {props.data.fotografia}</p>
                  <p>Scenografia: {props.data.scenografia}</p>
                  <p>Montaggio: {props.data.montaggio}</p>
                  <p>Costumi: {props.data.costumi}</p>
                  <p>Musiche: {props.data.musiche}</p>
                  <p>Regia: Roberto Capucci</p>
                </div>
                <div className="trailer_">
                  <h3>Trailer Ufficiale:</h3>
                  <a href={props.data.trailer}>
                    <img src={yt} alt="Youtube Logo" />
                  </a>
                </div>
              </div>
            </div>
            <div className="second_half">
              <div className="half_container">
                <div className="description_container">
                  <p>{props.data.description}</p>
                </div>
              </div>
            </div>
          </div>
          {props.data.has_video && (
            <div className="musical_video_container">
              <div className="embed_container">
                <iframe
                  className="embed_yt"
                  src="https://www.youtube.com/embed/gk-KDEY0vgU"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="video_musical_info">
                <div className="video_info_container">
                  <div>
                    <h1>VideoClip ufficiale di {props.data.title}</h1>
                  </div>
                  <div>
                    <div className="credits_">
                      <p>
                        Ideato e diretto: Roberto Capucci
                        <br></br>
                        Fotografia: {props.data.fotografia}
                        <br></br>
                        Montaggio: Eugenio Campisano
                        <br></br>
                        Scenografia: {props.data.scenografia}
                        <br></br>
                        Costume Design: {props.data.costumi}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </main>
      <footer>
        <Footer></Footer>
      </footer>
    </div>
  );
};
export default SubFilm;
