import React from "react";
import ad from "../../../images/Loghi/ad.png";
import DataHandler from '../../logic/all';
import ActingSection from './acting_sections/acting_section';
import './actingdirection.css';
import "aos/dist/aos.css";

const ActingDirection = () => {
  const {actingSections} = DataHandler();
  return (
    <div data-aos="fade-up" className="acting_container direction_outer">
      <div className="content_outer">
        <h1>
          THE ACTING<br></br>DIRECTION
        </h1>
      
        <p className="acting_direction_paragraph">
          The Acting Direction è il laboratorio per attori fondato su un metodo innovativo basato sulla direzione e evoluzione attraverso il lavoro con il regista.
          È un approccio formativo ed evolutivo diverso che crea gli strumenti e l’esperienza necessaria al professionismo.
          Comprende un ciclo di cinque master class e una palestra attoriale settimanale in sessioni da tre mesi, fruibili insieme o separatamente.
          È un percorso ideale per chi ha già una formazione, sia di base sia più completa.
        </p>

        <a className="acting_link" href="https://m.facebook.com/theactingdirection/" nofollow>
          <img src={ad} alt="The Acting Direction Logo" width="150" />
        </a>
      </div>

      {actingSections.map(section => {
        return <ActingSection 
          title={section.title}
          date={section.date}
          paragraph={section.paragraph}
          photos={section.photos}
        />
      })}
    </div>
  );
};
export default ActingDirection;

//<p>
//           The Acting Direction è un laboratorio semestrale indirizzato agli
//           interpreti, basato sul lavoro tra l’attore e il regista, ideato,
//           fondato e diretto da Roberto Capucci dal 2017. The Acting Direction si
//           pone l’obiettivo di lavorare con e attraverso la direzione, intesa non
//           solo sulla costruzione del personaggio, ma anche nell’impostare un
//           progetto sulle caratteristiche di ogni diverso partecipante. Proprio
//           focalizzandosi sulla direzione della propria performance e della
//           propria carriera, si può crescere e progredire di livello,
//           sperimentando e confrontandosi con Roberto o con altri registi
//           professionisti, imparando così a “costruire” un’interpretazione
//           propria o condivisa con il regista stesso. The Acting Direction è un
//           laboratorio “pratico”, "una palestra attoriale" dove confrontarsi con
//           i propri mezzi, i propri limiti e acquisire consapevolezza e
//           professionalità, modificando il concetto di attore da un ruolo
//           "passivo" ad uno “attivo". Acquisire i necessari strumenti per
//           “scoprire” il testo e costruire un’interpretazione. Imparare a
//           tradurre l’esigenze di un testo o di un regista in un linguaggio
//           pratico, interpretabile e condivisibile. Massimizzare la performance
//           nei provini affrontando le criticità, affinando le tecniche e le
//           capacità interpretative. Costruire il personaggio e farlo
//           proprio“diretti” da un regista professionista, sono alcune delle
//           caratteristiche che rendono diverso e unico questo tipo di
//           laboratorio, che fa della parte pratica il mezzo attraverso il quale
//           acquisire esperienza e professionalità, o nel caso dei professionisti
//           nel tenerla sempre allenata. The Acting direction si rivolge agli
//           attori dai principianti ai professionisti attraverso diverse tipologie
//           di classi e approcci lavorativi in base alle diverse competenze,
//           formando gruppi omogenei in un tempo prestabilito della durata di sei
//           mesi, con un incontro settimanale della durata di tre ore. A
//           coadiuvare i registi ci sarà sempre la figura di un attore
//           professionista che si occuperà della parte più tecnica di training,
//           riscaldamento e preparazione e che svolge un ruolo “ponte" con il
//           regista.
//         </p> 