import React, { useEffect } from "react";
import "../due_terzi/due_terzi.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
const DueTerzi = (props) => {
  return (
    <div data-aos="fade-up" className="element2">
      <Link to={props.film.path}>
        <div className="element_inside_container">
          <div data-aos="fade-left" className="container_inner">
            <div className="work_title">
              <h2>{props.film.title}</h2>
              <h4>{props.film.type}</h4>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
export default DueTerzi;
