import React from "react";
import "./footer.css";
import logon from "../../../images/Loghi/Logo orizzontale/PNG/logo_n.png";
import yt from "../../../images/Loghi/yt.png";
import vimeo from "../../../images/Loghi/vimeo.png";
import imdb from "../../../images/Loghi/imdb.png";
import wiki from "../../../images/Loghi/wiki.png";
import fb from "../../../images/Loghi/fb.png";
import ig from "../../../images/Loghi/ig.png";
const Footer = () => {
  return (
    <div className="footer_outer">
      <div className="fcc">
        <div className="footer_container">
          <div className="footer_content">
            <div className="footer_test">
              <img src={logon} alt="Logo Roberto Capucci 2021" />
            </div>
            <div className="contact_outer">
              <div>
                <a href="mailto:info@robertocapucci.it">
                  info@robertocapucci.it
                </a>
              </div>
              <div className="footer_logos">
                <a href="https://www.imdb.com/name/nm10959481/">
                  <img src={imdb} width="75" />
                </a>
                <a href="" className="black_inside">
                  <img src={fb} width="75" />
                </a>
                <a href="https://it.wikipedia.org/wiki/Roberto_Capucci_(regista)">
                  <img src={wiki} width="75" />
                </a>
                <a href="https://vimeo.com/user18659422">
                  <img src={vimeo} width="75" />
                </a>
                <a href="" className="black_inside">
                  <img src={ig} width="75" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="credits_row">
        <span>
          POWERED BY <a href="#"> G-GROUPS</a>
        </span>
      </div>
    </div>
  );
};
export default Footer;
