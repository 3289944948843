import React from "react";
import "./reel.css";
import { Link } from "react-router-dom";
import SubNav from "../subnav/subnav";

const Reel = () => {
  return (
    <div className="">
      <SubNav></SubNav>
      <div>
        <iframe
          src="https://player.vimeo.com/video/637530832?h=1ce7cde3dd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameborder="0"
          className="reel_vimeo_container"
          title="reel Roberto Capucci_DEF"
        ></iframe>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>
    </div>
  );
};
export default Reel;
