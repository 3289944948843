import React from "react";
import "aos/dist/aos.css";
import "./shorts.css";
import vimeo from "../../../images/Loghi/vimeo.png";
import yt from "../../../images/Loghi/yt.png";
const Shorts = (props) => {
  return (
    <div className={props.shorts.classN}>
      <div data-aos="fade-up" className="shorts_container">
        <div className="shorts_content_container">
          <div className="shorts_video_container">
            <video loop muted autoPlay={true}>
              <source src={props.shorts.video} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
      <div data-aos="fade-up" className="shorts_description_container">
        <div className="shorts_desc_">
          <div className="shorts_title">
            <h2>{props.shorts.title}</h2>
          </div>
          <div className="shorts_description">
            <p>{props.shorts.description}</p>
          </div>
          <div className="vimeo_logo_short">
            {props.shorts.is_on_youtube ? (
              <a href={props.shorts.youtube_path}>
                <img src={yt} className="vimeo" />
              </a>
            ) : (
              <a href={props.shorts.vimeo_url}>
                <img src={vimeo} className="vimeo" />
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Shorts;
