import React from "react";
import "./bio.css";
import "aos/dist/aos.css";
import wiki from "../../../images/Loghi/wiki.png";
import imdb from "../../../images/Loghi/imdb.png";
const Bio = () => {
  return (
    <div className="bio_row" data-aos="fade-up">
      <div className="bio_half">
        <div className="bio_inner capucci_bio"></div>
      </div>
      <div className="bio_half">
        <div className="bio_inner_">
          <div className="bio_title">
            <h1 className="title">BIO</h1>
          </div>
          <div className="bio_paragraph">
            <p>
              Roberto Capucci, romano, classe 1975, regista e autore, con
              "Ovunque tu sarai” firma il suo esordio cinematografico dopo una
              lunga gavetta iniziata sul set poco più che maggiorenne. Dopo più
              di dieci anni in produzione e post, studi accurati sulla
              recitazione e sulla direzione, inizia a dedicarsi alla sua vera
              passione: la regia cinematografica. Nel 2002 consegue il graduate
              alla TISCH della New York University in production and direction,
              successivamente inizia a scrivere e dirigere, videoclip,
              pubblicità e cortometraggi che spesso anche produce. il 4 Ottobre
              del 2021 esce su Netflix la sua opera seconda “ mio fratello, mia
              sorella” distribuita sulla piattaforma in tutto il mondo. Il
              cinema, oltre al suo mestiere, è la sua più grande passione: ama
              sperimentare, esplorare generi e tecniche diverse, sia nella
              scrittura sia nella regia, crede fortemente nel lavoro di squadra,
              e si dedica da 4 anni ad un laboratorio “the Acting Direction”
              basato sul lavoro tra regista e attore.
            </p>
          </div>
          <div className="bio_reference">
            <a href="https://it.wikipedia.org/wiki/Roberto_Capucci_(regista)">
              <img src={wiki} alt="Logo Wikipedia" />
            </a>
            <a href="https://www.imdb.com/name/nm10959481/">
              <img src={imdb} alt="Logo IMDb" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Bio;
