import React from "react";
import "aos/dist/aos.css";
import "./commercials.css";
import "react-typing-text-animation/dist/index.css";
import mc from "../../../images/Loghi/mcdonald.png";
import bialetti from "../../../images/Loghi/bialetti.png";
import fox from "../../../images/Loghi/fox.png";
import barilla from "../../../images/Loghi/barilla.png";
import omega from "../../../images/Loghi/omega.png";
const Commercials = () => {
  return (
    <div className="commercials_row" data-aos="fade-up">
      <div className="commercials_half">
        <div className="commercials_inner">
          <div className="commercials_title">
            <h1 className="title">COMMERCIALS</h1>
          </div>
          <div className="commercial_paragraph">
            <p>
              Pubblicità. La mia storia e una grande passione. Raccontare con le
              immagini una storia in pochi secondi.
            </p>
          </div>
          <div className="commercials_partners">
            <img src={mc} alt="logo Mc Donald" />
            <img src={fox} alt="logo Fox" />
            <img src={barilla} alt="logo Barilla" />
            <img src={bialetti} alt="logo Bialetti" />
            <img src={omega} alt="logo Omega Pharma" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Commercials;
