import React from "react";
import Container from "../container/container";
const Main = (props) => {
  return (
    <main className="main">
      <Container showvideo={props.showvideo}></Container>
    </main>
  );
};
export default Main;
