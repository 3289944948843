import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./film.css";
import "aos/dist/aos.css";
import arrow from "../../../images/arrow.png";
import useWindowDimensions from "../../logic/screensize";
const Film = (props) => {
  const vidRef = useRef(null);
  const playvideo = (e) => {
    vidRef.current.play();
    console.log("play");
  };
  const stopVideo = (e) => {
    vidRef.current.pause();
    console.log("pause");
  };
  useEffect(() => {}, [props]);
  if (props.showvideo) {
    return (
      <div
        data-aos="fade-up"
        className="film_container"
        onMouseOver={playvideo}
        onMouseOut={stopVideo}
      >
        <div className="video_container">
          <div className="goto_film">
            <div>
              <Link className="titles_film" to={props.film.path}>
                <div>
                  <img src={arrow} alt="follow" className="arrow" />
                </div>
                <h1>
                  {props.film.title}, {props.film.year}
                </h1>
              </Link>
            </div>
          </div>
          <div className="content_container"></div>
          <video loop muted ref={vidRef}>
            <source src={props.film.video} type="video/mp4" />
          </video>
        </div>
        <div className="img_container">
          <img src={props.film.cover} alt="" />
        </div>
      </div>
    );
  } else {
    return (
      <div data-aos="fade-up" className="film_container">
        <div className="img_container">
          <div className="goto_film">
            <div>
              <Link className="titles_film" to={props.film.path}>
                <div>
                  <img src={arrow} alt="follow" className="arrow" />
                </div>
                <h1>
                  {props.film.title}, {props.film.year}
                </h1>
              </Link>
            </div>
          </div>
          <div className="content_container"></div>
          <img src={props.film.cover} alt="" />
        </div>
      </div>
    );
  }
};
export default Film;
