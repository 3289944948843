import React, { useEffect, useState } from "react";
import "aos/dist/aos.css";
import UnTerzo from "../un_terzo/un_terzo";
import "./spot.css";
const Spots = (props) => {
  const [fewSpots, setFewSpots] = useState([]);
  const [buttonText, setButtonText] = useState("VEDI DI PIU");

  useEffect(() => {
    setFewSpots(props.spots.slice(0, 6));
  }, []);
  const handleClick = (e) => {
    e.preventDefault();
    if (buttonText === "VEDI DI PIU") {
      setButtonText("RIDUCI");
      setFewSpots(props.spots);
    } else {
      setButtonText("VEDI DI PIU");
      setFewSpots(props.spots.slice(0, 6));
    }
  };
  return (
    <div className="spots_container">
      <div className="spots_outer">
        {fewSpots.map((el, i) => (
          <UnTerzo spot={el} key={i}></UnTerzo>
        ))}
      </div>
      <div className="spots_button">
        <button onClick={handleClick}>
          <span>{buttonText}</span>
        </button>
      </div>
    </div>
  );
};
export default Spots;
