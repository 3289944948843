import React, { useState } from 'react';
import {classNames} from '../../../logic/classes'
import './acting_section.css';

const ActingSection = ({ title, date, paragraph, photos}) => {
    const [photosList, setPhotoList ] = useState([])

    return (
        <div className="actingSection_container">
            <div>
                <h2>{title} - {date}</h2>
                <p>{paragraph}</p>
            </div>
            <div>
                
                {photos.map((photo, index)=> <img 
                    className={classNames({
                        'acting_section_img': true,
                        'mrg_for_even_photo': index % 1 === 0,
                    })}
                    src={photo}>
                </img>)}
            </div>
        </div>
    );
};

export default ActingSection;