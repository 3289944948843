import React from "react";
import { Link } from "react-router-dom";
import arrow from "../../../images/arrow.png";
import cap from "../../../images/Loghi/Logo orizzontale/PNG/logo_b.png";
import "./subnav.css";
const SubNav = () => {
  return (
    <div className="nav_sub_container">
      <div className="athird arrow_div">
        <Link to={"/"}>
          <img src={arrow} alt="go back" className="arrow_sub" />
        </Link>
      </div>
      <div className="athird nav_logo">
        <Link to={"/"}>
          <img
            src={cap}
            alt="Roberto Capucci Logo 2021 White"
            className="cap_sub"
          />
        </Link>
      </div>
      <div className="athird xx"></div>
    </div>
  );
};
export default SubNav;
