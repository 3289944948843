import React from "react";
import "../header/header.css";
import Navbar from "../navbar/navbar";
import logob from "../../../images/Loghi/Logo orizzontale/PNG/logo_b.png";
import imdb from "../../../images/Loghi/imdb.png";
import wiki from "../../../images/Loghi/wiki.png";
import reel from "../../../videos/reel.mp4";
import { Link } from "react-router-dom";

const Header = (props) => {
  if (props.showvideo) {
    return (
      <header>
        <div className="header_bg">
          <div className="logo_row">
            <a href="https://www.imdb.com/name/nm10959481/">
              <img src={imdb} className="vimeo_cont" alt="logo IMDB" />
            </a>
            <div className="logo_cont">
              <img src={logob} alt="Logo Roberto Capucci 2021" />
            </div>
            <a href="https://it.wikipedia.org/wiki/Roberto_Capucci_(regista)">
              <img src={wiki} className="insta_cont" alt="Logo Wikipedia" />
            </a>
          </div>
          <div className="bg_reel">
            <video loop muted autoPlay={true}>
              <source src={reel} type="video/mp4" />
            </video>
          </div>
          <div className="reel_button_outer">
            <Link to="/reel-roberto-capucci-2021">
              <button className="reel_button_mobile">REEL 2021</button>
            </Link>
          </div>
        </div>
        <Navbar></Navbar>
      </header>
    );
  } else {
    return (
      <header>
        <div className="header_bg">
          <div className="logo_row">
            <a href="https://www.imdb.com/name/nm10959481/">
              <img src={imdb} className="vimeo_cont" alt="logo IMDB" />
            </a>
            <div className="logo_cont">
              <img src={logob} alt="Logo Roberto Capucci 2021" />
            </div>
            <a href="https://it.wikipedia.org/wiki/Roberto_Capucci_(regista)">
              <img src={wiki} className="insta_cont" alt="Logo Wikipedia" />
            </a>
          </div>
          <div className="reel_button_outer">
            <Link to="/reel-roberto-capucci-2021">
              <button className="reel_button_mobile">REEL 2021</button>
            </Link>
          </div>
        </div>
        <Navbar></Navbar>
      </header>
    );
  }
};
export default Header;
