import React, { useRef } from "react";
import DataHandler from "../../logic/all";
import "./navbar.css";
import logo_n from "../../../images/Loghi/Logo orizzontale/PNG/logo_n.png";
const Navbar = () => {
  const { scrollPage } = DataHandler();
  const hamb_ref = useRef(null);
  const list_ref = useRef(null);
  const handleClickNav = (e) => {
    e.preventDefault();
    hamb_ref.current.classList.toggle("change");
    hamb_ref.current.classList.toggle("close_position");
    list_ref.current.classList.toggle("close");
  };
  const mobileClickNav = (e, how_much) => {
    handleClickNav(e);
    scrollPage(how_much);
  };
  return (
    <div>
      <div className="navbar_container">
        <nav className="nav">
          <ul>
            <li onClick={() => scrollPage(1200)}>MOVIES</li>

            <li onClick={() => scrollPage(3000)}>BIO</li>

            <li onClick={() => scrollPage(6000)}>COMMERCIALS</li>

            <li onClick={() => scrollPage(7500)}>THE ACTING DIRECTION</li>

            <li onClick={() => scrollPage(9000)}>CONTACT</li>
          </ul>
        </nav>
        <div className="mobile_nav close" ref={list_ref}>
          <ul>
            <li onClick={(e) => mobileClickNav(e, 1000)}>MOVIES</li>
            <li onClick={(e) => mobileClickNav(e, 2000)}>BIO</li>
            <li onClick={(e) => mobileClickNav(e, 4800)}>COMMERCIALS</li>
            <li onClick={(e) => mobileClickNav(e, 8000)}>
              THE ACTING DIRECTION
            </li>
            <li onClick={(e) => mobileClickNav(e, 12000)}>CONTACT</li>
          </ul>
          <div className="nav_footer">
            <div className="nav_footer_row">
              <img src={logo_n} alt="Roberto Capucci Logo 2021" />
            </div>
          </div>
        </div>
        <div
          className="hamburger_menu close_position"
          ref={hamb_ref}
          onClick={handleClickNav}
        >
          <a className="hamburger_outer">
            <div className="line line-1"></div>
            <div className="line line-2"></div>
            <div className="line line-3"></div>
          </a>
        </div>
      </div>
    </div>
  );
};
export default Navbar;
