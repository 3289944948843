import React from "react";
import DataHandler from "../logic/all";
import Main from "../ui/main/main";
import { Switch, Route, useLocation } from "react-router-dom";
import Error from "../ui/error/error";
import SubFilm from "./subfilm/subfilm";
import SubSpots from "./subspot/subspot";
import Reel from "../ui/reel/reel.js";
import { TransitionGroup, CSSTransition } from "react-transition-group";
const Routes = (props) => {
  const loc = useLocation();
  const { film, spots } = DataHandler();
  return (
    <TransitionGroup>
      <CSSTransition timeout={200} classNames="fade" key={loc.key}>
        <Switch location={loc}>
          <Route exact path="/">
            <Main showvideo={props.showvideo}></Main>
          </Route>
          <Route path={film[0].path}>
            <SubFilm data={film[0]}></SubFilm>
          </Route>
          <Route path={film[1].path}>
            <SubFilm data={film[1]}></SubFilm>
          </Route>
          <Route path={spots[0].path}>
            <SubSpots data={spots[0]}></SubSpots>
          </Route>
          <Route path={spots[1].path}>
            <SubSpots data={spots[1]}></SubSpots>
          </Route>
          <Route path={spots[2].path}>
            <SubSpots data={spots[2]}></SubSpots>
          </Route>
          <Route path={spots[3].path}>
            <SubSpots data={spots[3]}></SubSpots>
          </Route>
          <Route path={spots[4].path}>
            <SubSpots data={spots[4]}></SubSpots>
          </Route>
          <Route path={spots[5].path}>
            <SubSpots data={spots[5]}></SubSpots>
          </Route>
          <Route path={spots[6].path}>
            <SubSpots data={spots[6]}></SubSpots>
          </Route>
          <Route path={spots[7].path}>
            <SubSpots data={spots[7]}></SubSpots>
          </Route>
          <Route path={spots[8].path}>
            <SubSpots data={spots[8]}></SubSpots>
          </Route>
          <Route path={spots[9].path}>
            <SubSpots data={spots[9]}></SubSpots>
          </Route>
          <Route path={spots[10].path}>
            <SubSpots data={spots[10]}></SubSpots>
          </Route>
          <Route path={spots[11].path}>
            <SubSpots data={spots[11]}></SubSpots>
          </Route>
          <Route path={spots[12].path}>
            <SubSpots data={spots[12]}></SubSpots>
          </Route>
          <Route path={spots[13].path}>
            <SubSpots data={spots[13]}></SubSpots>
          </Route>
          <Route path="/reel-roberto-capucci-2021">
            <Reel></Reel>
          </Route>
          <Route path="*">
            <Error></Error>
          </Route>
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};
export default Routes;
