import React from "react";
import SubNav from "../subnav/subnav";
import Footer from "../footer/footer";
import "./subspot.css";
const SubSpot = (props) => {
  return (
    <div className="">
      <header>
        <SubNav></SubNav>
      </header>
      <main>
        <div className="subspot_allfat">
          <div className="vimeo_vid_cont">
            <iframe
              className="iframe_video"
              src={props.data.vimeo_path}
              frameborder="0"
              allow="autoplay; fullscreen"
              allowfullscreen
            ></iframe>
          </div>
          <div className="spot_info_cont">
            <div className="spot_info">
              <h1>{props.data.titolo}</h1>
              <p className="credits_spot_paragraph">
                Agenzia e cdp: {props.data.agenzia}<br></br>
                Cliente: {props.data.cliente} <br></br>
                Campagna: {props.data.campagna} <br></br>
                Soggetto: {props.data.soggetto} <br></br>
                Creatività: {props.data.creatività} <br></br>
                Distribuzione: {props.data.distribuzione} <br></br>
                Talents: {props.data.talents} <br></br>
                Produzione: {props.data.prod} <br></br>
                Regia: {props.data.regia} <br></br>
                Fotografia: {props.data.fotografia} <br></br>
                Scenografia: {props.data.scenografia} <br></br>
                Montaggio: {props.data.montaggio} <br></br>
                Color: {props.data.color} <br></br>
                Musiche: {props.data.musiche} <br></br>
              </p>
            </div>
          </div>
        </div>
      </main>
      <footer>
        <Footer></Footer>
      </footer>
    </div>
  );
};

export default SubSpot;
