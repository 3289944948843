import { useState } from "react";
import mfms from "../../images/mfms.jpg";
import ovunque from "../../images/ovunque.jpg";
import reel_ovunque from "../../videos/film/ots.mp4";
import reel_mfms from "../../videos/film/mfms.mp4";
import reel_birth from "../../videos/short/giorno.mp4";
import reel_lucia from "../../videos/short/dalucia.mp4";
import allattamento_a from "../../images/spots/allattamento_a.png";
import allattamento_b from "../../images/spots/allattamento_b.png";
import walking from "../../images/spots/walking.png";
import ga from "../../images/spots/ga.png";
import blacklist from "../../images/spots/blacklist.png";
import ol from "../../images/spots/ol.png";
import web from "../../images/spots/60web.png";
import actual_calcio from "../../images/spots/actual_calcio.png";
import actual_tennis from "../../images/spots/actual_tennis.png";
import barilla from "../../images/spots/barilla.png";
import nail from "../../images/spots/nail.png";
import peugeot from "../../images/spots/peugeot.png";
import wallskin from "../../images/spots/wallskin.png";
import campagna_vaccinale from "../../images/spots/campagna_vaccinale_cover.png";
// Old Interpretazione Attiva Section Photos
// import img_0506 from '../../images/acting_sections_photos/interpretazione attiva/IMG_0506.jpg';
// import img_0508 from '../../images/acting_sections_photos/interpretazione attiva/IMG_0508.jpg';
// import img_2057 from '../../images/acting_sections_photos/interpretazione attiva/IMG_2057.jpg';
// import img_5344 from '../../images/acting_sections_photos/interpretazione attiva/IMG_5344.jpg';
// import img_9034 from '../../images/acting_sections_photos/interpretazione attiva/IMG_9034.jpg';
// import img_9063 from '../../images/acting_sections_photos/interpretazione attiva/IMG_9063.jpg';
// Old Interpretazione Avanzata Section Photos
// import img_0806 from '../../images/acting_sections_photos/interpretazione avanzata/IMG_0860.jpg';
// import img_0920 from '../../images/acting_sections_photos/interpretazione avanzata/IMG_0920.jpg';
// import img_2366 from '../../images/acting_sections_photos/interpretazione avanzata/IMG_2366.jpg';
// import img_2508 from '../../images/acting_sections_photos/interpretazione avanzata/IMG_2508.jpg';
// import img_3220 from '../../images/acting_sections_photos/interpretazione avanzata/IMG_3220.jpg';
// import img_3223 from '../../images/acting_sections_photos/interpretazione avanzata/IMG_3223.jpg';
// import img_3227 from '../../images/acting_sections_photos/interpretazione avanzata/IMG_3227.jpg';
// import img_6189 from '../../images/acting_sections_photos/interpretazione avanzata/IMG_6189 2.jpg';
// Old Improvvisazione Funzionale Section Photos
// import img_25 from '../../images/acting_sections_photos/improvvisazione funzionale/25.jpg';
// import img_348A1C3DF319_1 from '../../images/acting_sections_photos/improvvisazione funzionale/IMG_348A1C3DF319-1.jpeg';
// import img_9548 from '../../images/acting_sections_photos/improvvisazione funzionale/IMG_9548.jpg';
// import img_9551 from '../../images/acting_sections_photos/improvvisazione funzionale/IMG_9551.jpg';
// import img_9554 from '../../images/acting_sections_photos/improvvisazione funzionale/IMG_9554.jpg';
// import img_9555 from '../../images/acting_sections_photos/improvvisazione funzionale/IMG_9555.jpg';
// import img_9556 from '../../images/acting_sections_photos/improvvisazione funzionale/IMG_9556.jpg';
// Old Le emozioni primarie Section Photos
// import img_24 from '../../images/acting_sections_photos/emozioni primarie/24.jpg';
// import img_32 from '../../images/acting_sections_photos/emozioni primarie/32.jpg';
// import img_0811 from '../../images/acting_sections_photos/emozioni primarie/IMG_0811.jpg';
// import img_0814 from '../../images/acting_sections_photos/emozioni primarie/IMG_0814.jpg';
// import img_0819 from '../../images/acting_sections_photos/emozioni primarie/IMG_0819.jpg';
// import img_0824 from '../../images/acting_sections_photos/emozioni primarie/IMG_0824.jpg';
// import img_9049 from '../../images/acting_sections_photos/emozioni primarie/IMG_9049.jpg';
// import img_9055 from '../../images/acting_sections_photos/emozioni primarie/IMG_9055.jpg';
// import img_9075 from '../../images/acting_sections_photos/emozioni primarie/IMG_9075.jpg'; 
// Old Essere il personaggio Section Photos
// import img_23 from '../../images/acting_sections_photos/essere il personaggio/23.jpg';
// import img_0661 from '../../images/acting_sections_photos/essere il personaggio/IMG_0661.jpg';
// import img_0892 from '../../images/acting_sections_photos/essere il personaggio/IMG_0892.jpg';
// import img_0900 from '../../images/acting_sections_photos/essere il personaggio/IMG_0900.jpg';
// import img_1883 from '../../images/acting_sections_photos/essere il personaggio/IMG_1883.jpg';
// import img_1887 from '../../images/acting_sections_photos/essere il personaggio/IMG_1887.jpg';
// import img_1944 from '../../images/acting_sections_photos/essere il personaggio/IMG_1944.jpg';
// import img_8546 from '../../images/acting_sections_photos/essere il personaggio/IMG_8546.jpg';
// import img_senza_titolo_1 from '../../images/acting_sections_photos/essere il personaggio/senza_titolo_1.jpg';
// import img_senza_titolo_2 from '../../images/acting_sections_photos/essere il personaggio/senza_titolo_2.jpg';

// New Interpretazione Attiva Section Photos
import new_interpretazione_attiva from '../../images/acting_sections_photos/interpretazione attiva/new_interpretazione_attiva.jpeg';
// New Interpretazione Avanzata Section Photos
import new_interpretazione_avanzata from '../../images/acting_sections_photos/interpretazione avanzata/new_interpretazione_avanzata.jpeg';
// New Improvvisazione Funzionale Section Photos
import new_improvvisazione_funzionale from '../../images/acting_sections_photos/improvvisazione funzionale/new_improvvisazione_funzionale.jpeg';
// New Le emozioni primarie Section Photos
import new_emozioni_primarie from '../../images/acting_sections_photos/emozioni primarie/new_emozioni_primarie.jpeg';
// New Essere il personaggio Section Photos
import new_essere_il_personaggio from '../../images/acting_sections_photos/essere il personaggio/new_essere_il_personaggio.jpeg';

const DataHandler = () => {
  const [film, setFilm] = useState([
    {
      type: "Film",
      title: "Ovunque tu sarai",
      year: "2017",
      description:
        "Francesco (Primo Reggiani), Carlo (Ricky Memphis), Loco (Francesco Apolloni) e Giordano (Francesco Montanari), amici da una vita, partono da Roma direzione Madrid per festeggiare l’addio al celibato di Francesco ma soprattutto per fare, come successo già altre volte, un viaggio insieme. In realtà il viaggio è anche un pretesto per andare a vedere la propria squadra del cuore in trasferta in Champions League. Nel loro percorso incrociano quello di Pilar (Ariadna Romero), una splendida cantante spagnola che, inconsapevolmente, porta scompiglio all’interno di un gruppo affiatato favorendo, tra imprevisti, risate e situazioni rocambolesche, anche il venire a galla di diversità, insicurezze e parole mai dette. Al loro ritorno nulla sarà più come prima. Ovunque tu sarai ha vinto diversi premi tra i quali il Magna Grecia Film Festival come miglior regia nel 2017, Il Circeo film arte e Cultura con la direzione artistica di Paolo Genovese come miglior opera prima del 2017, il premio psiche come film 2017 e un premio speciale al Giffoni film Festival per Francesco Montanari e Francesco Apolloni.",
      trailer: "https://www.youtube.com/watch?v=bcuLZ-I7TL4",
      path: "/ovunque-tu-sarai",
      cover: ovunque,
      video: reel_ovunque,
      prod: "Una produzione Camaleo cine - Ulula Films",
      produttori:
        "Camaleo Cinema Roberto Cipullo Mario Pezzi	Miriam Ruiz Mateos",
      attori:
        "Ricky Memphis	Primo Reggiani Francesco Montanari Francesco Apolloni	Ariadna Romero",
      soggetto: "Roberto Capucci, Francesco Apolloni",
      sceneggiatura: "Roberto Capucci, Francesco Apolloni",
      fotografia: "Andrea Arnone",
      scenografia: "Gaspare De Pascali",
      montaggio: "Francesco Galli",
      costumi: "Alessia Condò",
      musiche: "Daniele Bonaviri	Valerio Calisse	Marco Conidi",
      has_video: true,
    },
    {
      type: "Film",
      title: "mio fratello, mia Sorella",
      year: "2021",
      description:
        "Alla morte del padre, Tesla e suo fratello Nik si ritrovano, per un singolare patto successorio, a dover convivere per un anno sotto lo stesso tetto, pur non essendosi più visti da più di vent'anni. Nella casa vivono anche i figli di Tesla: Sebastiano, un violoncellista di grande talento affetto da schizofrenia ad alto funzionamento, al quale la donna ha dedicato la vita e un'ossessiva e soffocante protezione, e Carolina, con la quale invece ha un rapporto difficile e conflittuale. La convivenza difficile innescherà scontri e continui battibecchi tra Nik e Tesla, due fratelli agli antipodi, e la nascita di un inaspettato forte legame tra Nik e suo nipote Sebastiano. Col tempo tutti troveranno pian piano un equilibrio, fino a quando una serie di eventi porteranno i personaggi a dover fare i conti con le proprie paure e segreti, in un difficile viaggio verso il perdono e l'accettazione di sé stessi e dei loro legami affettivi e familiari.",
      trailer: "https://www.youtube.com/watch?v=XZLffMvo-GM",
      path: "/mio-fratello-mia-sorella",
      video: reel_mfms,
      cover: mfms,
      prod: "Una produzione Lotus prod. una società del LEONE FILM GROUP per NETFLIX e R.T.I",
      attori:
        "Alessandro Preziosi, Claudia Pandolfi, Ludovica Martino, Stella Egitto, Francesco Cavallo e Caterina Murino",
      soggetto: "Roberto Capucci",
      sceneggiatura: "Roberto Capucci e Paola Mammini",
      produttori: "Davide Blandamura Marion Marcucci Manuel Orlandi",
      fotografia: "Andrea Arnone",
      scenografia: "Tiziana Liberotti",
      montaggio: "Francesco Galli",
      costumi: "Alessia Condò",
      musiche: "Valerio Calisse Daniele Bonaviri",
      has_video: false,
    },
  ]);
  const [spots, setSpots] = useState([
      {
        type: "Spot",
        titolo: "Campagna vaccinale influenzale covid 23/24",
        cliente: "Ministero della Salute",
        campagna: "Spot tv 30\" – Campagna vaccinale influenzale covid 23/24",
        creatività: "Roberto Capucci, Massimo Andreozzi",
        distribuzione: "/",
        agenzia: 'made in Genesi',
        soggetto: "chi ti protegge?",
        regia: "Roberto Capucci",
        fotografia: "Andrea Arnone",
        scenografia: "Tiziana Liberotti",
        montaggio: "Francesco Galli",
        musiche: "Valerio Calisse",
        color: "/",
        talents: "Lucia Batassa",
        path: "/campagna-vaccinale-covid-2023-2024",
        vimeo_path: "https://www.youtube.com/embed/0OTx--4qkHc?si=kyOVV-mxiTAVQjSY",
        cover: campagna_vaccinale,
    },
    {
      type: "Spot",
      titolo: "CAMPAGNA SENSIBILIZZAZIONE ALLATTAMENTO",
      vimeo_path: "https://player.vimeo.com/video/405773921",
      cliente: "Ministero della salute",
      campagna: "Sensibilizzazione allattamento naturale",
      soggetto: "Spalline",
      creatività: "Hdra",
      distribuzione: "free tv pubblicità progresso",
      talents: "Nuzzo e Di Biase",
      prod: "One More",
      regia: "Roberto Capucci",
      fotografia: "Andrea Arnone",
      scenografia: "Tiziana Liberotti",
      montaggio: "Diego Capitani",
      color: "Ercole Cosmi",
      path: "/allattamento-a",
      cover: allattamento_a,
    },
    {
      type: "Spot",
      titolo: 'CAMPAGNA SENSIBILIZZAZIONE ALLATTAMENTO "ESCILE" ',
      descrizione: "",
      vimeo_path: "https://player.vimeo.com/video/405774480",
      cliente: "Ministero della salute",
      campagna: "Sensibilizzazione allattamento naturale",
      soggetto: "Escile",
      creatività: "Hdra",
      distribuzione: "free tv pubblicità progresso",
      talents: "Nuzzo e Di Biase",
      prod: "One More",
      regia: "Roberto Capucci",
      fotografia: "Andrea Arnone",
      scenografia: "Tiziana Liberotti",
      montaggio: "Montaggio: Diego Capitani",
      path: "/allattamento-b",
      cover: allattamento_b,
    },
    {
      type: "Spot",
      titolo: "FOX-MCDONALD’S SERIE TV: WALKING DEAD",
      vimeo_path: "https://player.vimeo.com/video/247507911",
      cliente: "Mc donald’s",
      campagna: "Mc Delivery",
      soggetto: "Walking Dead",
      creatività: "Fox Italia-Roberto Capucci",
      distribuzione: "Fox Italia",
      talents:
        "Actual, Renata Malinconico, Giulio Forges, Eleonora Siro, Adriano Ricci",
      prod: "Kinoview",
      regia: "Roberto Capucci",
      fotografia: "Leonardo Mirabilia",
      scenografia: "Tiziana Liberotti",
      montaggio: "Montaggio: Diego Capitani",
      color: "Ercole Cosmi",
      path: "/fox-mcdonald-walking-dead",
      cover: walking,
    },
    {
      type: "Spot",
      titolo: "FOX-MCDONALD’S SERIE TV: GREY’S ANATOMY",
      vimeo_path: "https://player.vimeo.com/video/247507655",
      cliente: "Mc donald’s",
      campagna: "Mc Delivery",
      soggetto: "Grey's Anatomy",
      creatività: "Fox Italia-Roberto Capucci",
      distribuzione: "Fox Italia",
      talents:
        "Actual, Renata Malinconico, Giulio Forges, Eleonora Siro, Adriano Ricci",
      prod: "Kinoview",
      regia: "Roberto Capucci",
      fotografia: "Leonardo Mirabilia",
      scenografia: "Tiziana Liberotti",
      montaggio: "Montaggio: Diego Capitani",
      color: "Ercole Cosmi",
      path: "/fox-mcdonald-greys-anatomy",
      cover: ga,
    },
    {
      type: "Spot",
      titolo: "FOX-MCDONALD’S SERIE TV: BLACKLIST",
      vimeo_path: "https://player.vimeo.com/video/247507519",
      cliente: "Mc donald’s",
      campagna: "Mc Delivery",
      soggetto: "BlackList",
      creatività: "Fox Italia-Roberto Capucci",
      distribuzione: "Fox Italia",
      talents:
        "Actual, Renata Malinconico, Giulio Forges, Eleonora Siro, Adriano Ricci",
      prod: "Kinoview",
      regia: "Roberto Capucci",
      fotografia: "Leonardo Mirabilia",
      scenografia: "Tiziana Liberotti",
      montaggio: "Montaggio: Diego Capitani",
      color: "Ercole Cosmi",
      path: "/fox-mcdonald-blacklist",
      cover: blacklist,
    },
    {
      type: "Spot",
      titolo: "FOX-MCDONALD’S SERIE TV: OUTLANDER",
      vimeo_path: "https://player.vimeo.com/video/247507798",
      cliente: "Mc donald’s",
      campagna: "Mc Delivery",
      soggetto: "Outlander",
      creatività: "Fox Italia-Roberto Capucci",
      distribuzione: "Fox Italia",
      talents:
        "Actual, Renata Malinconico, Giulio Forges, Eleonora Siro, Adriano Ricci",
      prod: "Kinoview",
      regia: "Roberto Capucci",
      fotografia: "Leonardo Mirabilia",
      scenografia: "Tiziana Liberotti",
      montaggio: "Montaggio: Diego Capitani",
      color: "Ercole Cosmi",
      path: "/fox-mcdonald-outlander",
      cover: ol,
    },
    {
      type: "Spot",
      titolo: "MASTER 30 ACTUAL CALCIO-BASKET",
      cliente: "Mc donald’s",
      campagna: "Mc Delivery",
      soggetto: "Calcio-Basket",
      creatività: "Fox Italia-Roberto Capucci",
      distribuzione: "Fox Italia",
      talents:
        "Actual, Renata Malinconico, Giulio Forges, Eleonora Siro, Adriano Ricci",
      prod: "Kinoview",
      regia: "Roberto Capucci",
      fotografia: "Leonardo Mirabilia",
      scenografia: "Tiziana Liberotti",
      montaggio: "Montaggio: Diego Capitani",
      color: "Ercole Cosmi",
      path: "/master-30-actual-calcio",
      vimeo_path: "https://player.vimeo.com/video/247508172",
      cover: actual_calcio,
    },
    {
      type: "Spot",
      titolo: "MASTER 30 ACTUAL TENNIS-F1",
      vimeo_path: "https://player.vimeo.com/video/247511630",
      durata: 30,
      cliente: "Mc donald’s",
      campagna: "Mc Delivery",
      soggetto: "Tennis-F1",
      creatività: "Fox Italia-Roberto Capucci",
      distribuzione: "Fox Italia",
      talents:
        "Actual, Renata Malinconico, Giulio Forges, Eleonora Siro, Adriano Ricci",
      prod: "Kinoview",
      regia: "Roberto Capucci",
      fotografia: "Leonardo Mirabilia",
      scenografia: "Tiziana Liberotti",
      montaggio: "Montaggio: Diego Capitani",
      color: "Ercole Cosmi",
      path: "/fox-mcdonald-actual",
      cover: actual_tennis,
    },
    {
      type: "Spot",
      titolo: "SPM Soggetto Dream",
      cliente: "SPM",
      creatività: "Roberto Capucci",
      campagna: "Dream",
      soggetto: "Orzata",
      distribuzione: "Web",
      talents: "Ariadna Romero",
      prod: "Kinoview",
      regia: "Roberto Capucci",
      fotografia: "Andrea Arnone",
      montaggio: "Diego Capitani",
      scenografia: "Tiziana Liberotti",
      color: "Ercole Cosmi",
      path: "/spm-soggetto-dream",
      vimeo_path: "https://player.vimeo.com/video/247509364",
      cover: web,
    },
    {
      type: "Spot",
      titolo: "Barilla INSIDE",
      path: "/commercial-clip-barilla",
      cliente: "National Geographics- Barilla",
      campagna: "Guarda tu stesso",
      soggetto: "Inside - sughi pronti",
      creatività: "National Geographics - Roberto Capucci",
      distribuzione: " Fox Italia - National Geographic channel",
      prod: " Emma Film",
      regia: " Roberto Capucci",
      fotografia: "Andrea Arnone",
      montaggio: "Montaggio: Diego Capitani",
      color: "Ercole Cosmi",
      vimeo_path: "https://player.vimeo.com/video/219662649",
      cover: barilla,
    },
    {
      type: "Spot",
      titolo: "NAIL EXPERT",
      path: "/nail-expert",
      cliente: "/",
      campagna: "/",
      tipologia: "/",
      creatività: "/",
      distribuzione: "/",
      prod: "/",
      regia: "Roberto Capucci",
      fotografia: "/",
      montaggio: "/",
      color: "/",
      vimeo_path: "https://player.vimeo.com/video/71344274",
      cover: nail,
    },
    {
      type: "Spot",
      titolo: "Peugeot 5008",
      cliente: "Peugeot",
      campagna: "I consigli della tata",
      soggetto: "La festa",
      creatività: " Fox Italia-Roberto Capucci",
      distribuzione: "Fox Italia",
      prod: "Deer Vallely film",
      regia: "Roberto Capucci",
      fotografia: "Maura Morales",
      montaggio: "Diego Capitani",
      color: "Ercole Cosmi",
      path: "/peugeot-41",
      vimeo_path: "https://player.vimeo.com/video/77489384",
      cover: peugeot,
    },
    {
      type: "Spot",
      titolo: "WALLSKIN 60",
      cliente: "Wallskin",
      campagna: "/",
      creatività: "Roberto Capucci",
      distribuzione: "/",
      prod: "Deer Vallely film",
      regia: "Roberto Capucci",
      fotografia: "Andrea Arnone",
      montaggio: "/",
      path: "/wallskin-60",
      vimeo_path: "https://player.vimeo.com/video/121657780",
      cover: wallskin,
    },

  ]);
  const [shorts, setShorts] = useState([
    {
      type: "Short",
      title: "Il giorno del mio compleanno - The day of my birthday",
      description:
        "Andrea è tornato per festeggiare il suo compleanno con le persone che ama. Attraverso un gioco inaspettato, donerà qualcosa di speciale ad ognuno di loro.",
      actors:
        "Gian Marco Tavani, Sara Zanier, Giulio Forges Davanzati, Cinzia Monreale, Valerio Di Benedetto, Gabriele Corrirossi, Victor Tavani",
      others: "PH: Maura Morales Bergmann ED: Annalisa Schillaci",
      conc: "",
      prod: "Maria Laura Artini per Crossover",
      path: "/il-giorno-del-mio-compleanno",
      vimeo_url: "https://vimeo.com/68136000",
      classN: "shorts_row_reverse",
      video: reel_birth,
      is_on_youtube: false,
      youtube_path: "",
    },
    {
      type: "Short",
      title: "Da Lucia",
      description:
        "Mentre la guerra imperversa, da Lucia si ritrova sempre più spesso un gruppo di amiche non più giovanissime, in cerca di gustose ricette e sogni reconditi.",
      actors: " Lucia Batassa, Nicoletta Boris, Gloria Coco",
      others:
        "PH: Maura Morales Bergmann ED: Andrea Maguolo  SC: Madi Celli CS: Antonio De Petrillo",
      conc: "per gentile concessione di Blue Suede Shoots",
      prod: "prodotto da Daniele Carelli per Blue Suede Shoots",
      path: "/da-lucia",
      vimeo_url: "https://vimeo.com/38937423/",
      classN: "shorts_row",
      video: reel_lucia,
      is_on_youtube: true,
      youtube_path: "https://www.youtube.com/watch?v=13rFQhdbBho&t=291s",
    },
  ]);
  const [actingSections, setActingSections] = useState([
    {
      type: "actingDrection",
      title: "Interpretazione Attiva",
      date: "3 giorni dalle 10 alle 18",
      paragraph: "Il concetto alla base della master class è l’introduzione del concetto di INTERPRETAZIONE in antitesi a quello di RECITAZIONE e come passare da soggetto passivo (attore-esecutore) a soggetto attivo (interprete-creativo). Il lavoro sulle scene è estremamente creativo e si lavora nei tre giorni su diversi aspetti: dalla proposta creativa, all'utilizzo del corpo in scena, al lavoro sull’intenzione, alla costruzione dell'identità artistico/creativa dell’attore.",
      // photos: [img_0506, img_0508, img_2057, img_5344, img_9034, img_9063],
      photos: [new_interpretazione_attiva],
    },
    {
      type: "actingDrection",
      title: "Interpretazione Avanzata",
      date: "3 giorni dalle 10 alle 18",
      paragraph: "Il focus della master class è sul lavoro reale sul personaggio e sul suo arco. L’attore lavora sul personaggio all’interno del suo sviluppo narrativo. Può così affrontare un lavoro più completo utilizzando obiettivi complessivi, arco narrativo, dinamiche e conflitti con gli altri personaggi. Si introduce il tema della “scelta” dell’attore impostando la propria specifica interpretazione, nella creazione e l’affermazione di una proposta unica e originale in accordo con il lavoro del regista.",
      // photos: [img_0806, img_0920, img_2366, img_2508, img_3220, img_3223, img_3227, img_6189],
      photos: [new_interpretazione_avanzata,],
    },
    {
      type: "actingDrection",
      title: "Improvvisazione Funzionale",
      date: "3 giorni dalle 10 alle 18",
      paragraph: "l’improvvisazione non è affidarsi al caso, all’istinto e al momento, ma imparare a rendere l’intuizione e la scelta funzionale alla scena e alla propria proposta. Il lavoro parte dalla capacità di impostare un’interpretazione, con la possibilità di trasformare una scena in qualcosa di assolutamente inedito. Il lavoro sulla scelta e sull’ascolto in scena dell’altro personaggio è estremamente avvincente e gratificante. Questa master class permette all’attore di imparare a gestire la tecnica con l’istinto e la capacità di reazione, ascolto e accoglienza degli altri interpreti in scena. Oltre alle scene il lavoro include diversi e specifici esercizi d’improvvisazione dove esercitare a pieno istinto, tecnica e creatività.",
      // photos: [img_25, img_348A1C3DF319_1, img_9548, img_9551, img_9554, img_9555, img_9556],
      photos: [new_improvvisazione_funzionale],
    },
    {
      type: "actingDrection",
      title: "Le emozioni primarie",
      date: "5 giorni dalle 10 alle 18",
      paragraph: "La Master Class “Le emozioni primarie” è alla base del metodo innovativo di “The Acting Direction” di Roberto Capucci e si sviluppa in un percorso completo sulle emozioni, utilizzate non solo come obiettivo della performance, ma come vero e proprio strumento di lavoro. L’emozione viene introdotta per comprenderla attraverso un approccio psicologico, fisiologico e antropologico. Comprendere cos’è e come funziona l’emozione permette un lavoro innovativo sul testo: l’emozione viene riconosciuta e classificata diventando uno strumento di lavoro per l'attore a tutti gli effetti. Tutto questo è funzionale alla fase successiva, la più intensa, che è quella dell'evocazione: Come attingere dai sensi, dalla coscienza di sé e dalla memoria emotiva per lavorare sulla verità e la presenza dell'emozione. Infine L'emozione, una volta evocata, va gestita a servizio del lavoro in scena, nell'ultima fase, attraverso il lavoro sul campo con il regista. L’emozione diventa “strumenton\" e fine ultimo per una performance più vera e autentica. Le emozioni primarie, la master class con la quale imparare ad usare le emozioni per emozionare.",
      // photos: [img_24, img_32, img_0811, img_0814, img_0819, img_0824, img_9049, img_9055, img_9075],
      photos: [new_emozioni_primarie],
    },
    {
      type: "actingDrection",
      title: "Essere il personaggio",
      date: "3 giorni dalle 10 alle 18",
      paragraph: "Master Class intensiva e conclusiva del percorso, dove si lavora attivamente sulla capacità di definire l’interpretazione e sulla personalizzazione e caratterizzazione del ruolo, attraverso l’utilizzo di uno strumento analitico e assolutamente inedito di lavoro avanzato. Per rendere \"proprio\" e \"unico\" il personaggio bisogna prima di tutto comprenderlo a fondo e mai giudicarlo, imparando a raccogliere tutti gli \"indizi\" sparsi nel testo, affinandoli attraverso la propria essenza ed esperienza e che permette una conoscenza profonda, costruire connessioni, analizzare le differenze, per arrivare a compiere la scelta più autentica e adatta a raggiungere la verità del personaggio. Ogni scelta definita necessita di una responsabilità forte, creando le basi per essere attori più definiti ed efficaci e acquisire consapevolezza e coscienza di sè. Coraggio, consapevolezza e carisma, sono le qualità che gli attori più importanti hanno e che hanno acquisito compiendo scelte definite e assumendosi responsabilità decise.",
      // photos: [img_23, img_0661, img_0892, img_0900, img_1883, img_1887, img_1944, img_8546, img_senza_titolo_1, img_senza_titolo_2],
      photos: [new_essere_il_personaggio],
    },
  ])
  const scrollPage = (how_much) => {
    window.scrollTo({
      top: how_much,
      behavior: "smooth",
    });
  };
  return { film, spots, shorts, actingSections, scrollPage };
};
export default DataHandler;