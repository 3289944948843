import "./App.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./components/ui/routes";
import { useEffect } from "react";
import useWindowDimensions from "./components/logic/screensize";

function App() {
  const { windowDimensions, showVideo } = useWindowDimensions();
  useEffect(() => {
    console.log(windowDimensions.width, showVideo);
    Aos.init({
      duration: 500,
      disable: function () {
        return windowDimensions.width < 800;
      },
    });
  }, [windowDimensions]);
  return (
    <div className="App">
      <Router>
        <Routes showvideo={showVideo}></Routes>
      </Router>
    </div>
  );
}

export default App;
