import React, { useRef } from "react";
import "./container.css";
import UnTerzo from "../un_terzo/un_terzo";
import DueTerzi from "../due_terzi/due_terzi";
import DataHandler from "../../logic/all";
import Bio from "../bio/bio";
import Commercials from "../commercials/commercials";
import Spots from "../spots/spots";
import ActingDirection from "../actingdirection/actingdirection";
import Footer from "../footer/footer";
import Header from "../header/header";
import Film from "../film/film";
import Shorts from "../shorts/shorts";
const Container = (props) => {
  const { film, spots, shorts } = DataHandler();

  return (
    <div className="allfather_container">
      <Header showvideo={props.showvideo}/>
      <div className="film_container_c">
        <Film showvideo={props.showvideo} film={film[1]}></Film>
        <Film showvideo={props.showvideo} film={film[0]}></Film>
      </div>
      <Bio></Bio>
      <Shorts shorts={shorts[0]}></Shorts>
      <Shorts shorts={shorts[1]}></Shorts>
      <Commercials></Commercials>
      <Spots spots={spots}></Spots>
      <ActingDirection/>
      <Footer/>
    </div>
  );
};
export default Container;
